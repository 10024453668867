import React, { Component } from 'react'
import VendorsLeadIntakeModel from './model/VendorsLeadIntakeModel'
import FirstIntakeStep from './FirstIntakeStep'

// interface VendorsLeadIntakeAppProps{}

export default class VendorsLeadIntakeApp extends Component {
  model: VendorsLeadIntakeModel

  constructor (props: any) {
    super(props)
    this.model = new VendorsLeadIntakeModel()
  }

  componentDidMount () {
    if (ahoy) {
      ahoy.track('VendorsLeadIntakeApp mounted')
    }
  }

  render () {
    return (
    <div className='lead_intake_main d-flex justify-content-center w-100'>
      {this.model.currentStep === 0 && <FirstIntakeStep model={this.model} />}
    </div>)
  }
}
