import { observable, action, makeObservable } from 'mobx'

type IntakeOption = {
    icon?: string
    title: string
    add_info?: string
}

export interface IVendorsLeadIntakeModel {
  currentStep: number
  setCurrentStep: (newStep: number) => void
  intakeOptions: IntakeOption[]
  selectedIntakeOption: IntakeOption | 'another'
  setIntakeOption: (id: number | 'another') => void
}

class VendorsLeadIntakeModel implements IVendorsLeadIntakeModel {
  currentStep: number
  intakeOptions: IntakeOption[]
  selectedIntakeOption: IntakeOption | 'another'

  constructor () {
    this.currentStep = 0
    this.intakeOptions = [
      { icon: '📄', title: 'Печать материалов', add_info: '(документы, баннеры, визитки, книги, постеры и другое)' },
      { icon: '🌟', title: 'Брендирование продукции', add_info: '(ручки, кружки, блокноты, одежда с логотипом)' },
      { icon: '🚀', title: 'Специальные проекты', add_info: '(нестандартная печать, твердые обложки и другое)' }
    ]

    this.selectedIntakeOption = this.intakeOptions[0]

    makeObservable(this, {
      currentStep: observable,
      intakeOptions: observable,
      setCurrentStep: action
    })
  }

  setCurrentStep (newStep: number) {
    this.currentStep = newStep
  }

  setIntakeOption (id: number | 'another') {
    if (id === 'another') {
      this.selectedIntakeOption = id
    } else {
      this.selectedIntakeOption = this.intakeOptions[id]
    }
  }
}

export default VendorsLeadIntakeModel
