import React, { FC } from 'react'
import { IVendorsLeadIntakeModel } from './model/VendorsLeadIntakeModel'
import LandingImg from '../../../../assets/images/vendors/lead_intake/LandingImg.png'
import Stars from '../../../../assets/images/vendors/lead_intake/Stars.png'
import Mark from '../../../../assets/images/vendors/lead_intake/Mark.png'
import People from '../../../../assets/images/vendors/lead_intake/People.png'

interface IFirstIntakeStep {
    model: IVendorsLeadIntakeModel
}

const FirstIntakeStep: FC<IFirstIntakeStep> = ({ model }) => (
    <div className='w-100 first_step'>
        <div className='main_block d-flex gap-5'>
            <div className='main_info'>
                <h1 className='main_heading_bg'>Ищете типографию?</h1>
                <div className='main_subheading'>
                    <h3><strong className='main_heading_brand'>Оправьте заявку</strong> лучшим типографиям <strong className='main_heading_brand'>Астаны</strong> и получите цены и предложение</h3>
                </div>
                <form action={() => model.setCurrentStep(1)}>
                    <h6 className='mb-3'>Выберите подходящий вариант:</h6>
                    {model.intakeOptions.map((option, index) => (
                        <div className='mb-3 d-flex flex-wrap-none'>
                            <input type='radio' id={option.title} name='intake_select' value={index} />
                            <label className='ms-1' htmlFor={option.title}>{option.icon} <strong>{option.title}</strong> {option.add_info}</label>
                        </div>
                    ))}
                    <div className='d-flex flex-wrap-none'>
                        <input type='radio' id='another' name='intake_select' value='another' />
                        <label className='ms-2' htmlFor='another'><strong>Другое</strong></label>
                    </div>
                </form>
                <div className='actions d-flex align-items-center justify-content-end gap-3'>
                    <h6>Без регистрации!</h6>
                    <button className='black_button' type='button'>Далее</button>
                </div>
            </div>
            <div className="main_img">
                <img className='w-100 h-auto' src={LandingImg} alt='Typography' />
            </div>
        </div>
        <div className="benefits_block d-flex justify-content-center align-items-center flex-wrap gap-5">
            <div className="benefit_block d-flex align-items-center gap-1">
                <div className="benefit_info">
                    <h2>4.9+</h2>
                    <sub>Оценка пользователей</sub>
                </div>
                <img src={Stars} alt="Rating" />
            </div>
            <div className="benefit_block d-flex align-items-center gap-1">
                <div className="benefit_info">
                    <h2>856+</h2>
                    <sub>Проверенных типографий</sub>
                </div>
                <img src={Mark} alt="Rating" />
            </div>
            <div className="benefit_block d-flex align-items-center gap-1">
                <div className="benefit_info">
                    <h2>23 745</h2>
                    <sub>Человек воспользовалось</sub>
                </div>
                <img src={People} alt="Rating" />
            </div>
        </div>
        <div className="info_steps simple_info_block">
            <h2><u>Как это работает?</u></h2>
            <div className='d-flex mt-5 flex-column gap-3 align-items-center align-items-md-start flex-md-row walkthrough'>
                <div className='info_step'>
                <div className='d-flex align-items-center justify-content-start mb-3'>
                    <h2>1. Форма</h2>
                </div>
                <p>Заполните простую форму для определение вашего запроса</p>
                </div>
                <div className='next_arrow for_brand_color_line d-none d-md-block mt-3'></div>
                <div className='next_arrow for_brand_color_line mobile d-block d-md-none'></div>
                <div className='info_step'>
                <div className='d-flex align-items-center justify-content-start mb-3'>
                    <h2>2. Отправьте запрос</h2>
                </div>
                <p>Типографии которые максимально подходят под вас получат ваше запрос</p>
                </div>
                <div className='next_arrow for_brand_color_line d-none d-md-block mt-3'></div>
                <div className='next_arrow for_brand_color_line mobile d-block d-md-none'></div>
                <div className='info_step'>
                <div className='d-flex align-items-center justify-content-start mb-3'>
                    <h2>3. Предложения!</h2>
                </div>
                <p>Типография которая сможем оформить ваш запрос напишет/позвонит вам с предложением</p>
                </div>
            </div>
        </div>
        <div className="services_banner block">
            <div className="services_banner_content w-100">
                <h2>Хотите выбрать типографию из списка?</h2>
                <div className="black_button">Смотреть списком</div>
            </div>
        </div>
    </div>
)

export default FirstIntakeStep
